import { userConstants } from '../../constants/user_constants'

let initialState = {
  auth: false,
  loginPending: false,
  token: { email: '' },
  user: [{ role: null }],
  usersGroup: [],
  pendingSelectUserRole: true,
}

export function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.SELECTED_USER_SUCCESS:
      let userEmail = ''

      if (action.userSelected.operatorUser) {
        userEmail = action.userSelected.operatorUser.email
      }
      if (action.userSelected.supervisor) {
        userEmail = action.userSelected.supervisor.email
      }
      if (action.userSelected.technician) {
        userEmail = action.userSelected.technician.email
      }
      if (action.userSelected.financialUser) {
        userEmail = action.userSelected.financialUser.email
      }

      localStorage.setItem('SELECTED_ROLE', action.selectedRole)

      return {
        ...state,
        user: { ...action.userSelected, userEmail },
        infoPending: false,
        pendingSelectUserRole: false,
      }
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loginPending: true,
        auth: false,
      }
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        auth: true,
        loginPending: false,
      }
    case userConstants.LOGIN_FAILURE:
      return {}
    case userConstants.LOGOUT:
      return {}
    case userConstants.INFO_REQUEST:
      return {
        ...state,
        infoPending: true,
      }
    case userConstants.INFO_SUCCESS:
      return {
        ...state,
        usersGroup: action.user,
        infoPending: false,
      }
    case userConstants.INFO_FAILURE:
      return {
        ...state,
      }
    case userConstants.TOKEN_REQUEST:
      return {
        ...state,
        tokenPending: true,
      }
    case userConstants.TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
        loginPending: false,
        tokenPending: false,
        auth: true,
      }
    case userConstants.TOKEN_FAILURE:
      return {
        ...state,
        tokenPending: false,
        error: action.error,
        loginPending: false,
        token: { email: '' },
      }
    case userConstants.REFRESH_REQUEST:
      return {
        ...state,
        tokenPending: true,
      }
    case userConstants.REFRESH_SUCCESS:
      return {
        ...state,
        tokenPending: false,
        token: action.token,
      }
    case userConstants.REFRESH_FAILURE:
      return {
        ...state,
        tokenPending: false,
        error: action.error,
      }
    case userConstants.AUTH_TRUE: {
      return {
        ...state,
        auth: true,
      }
    }
    default:
      return state
  }
}
