import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import PaymentMode from '../../constants/enums/PaymentMode'
import { handleTrueFalsyTermSwitch } from '../../helpers/filters'
import { isCauUser, isFinancial, isManager, isManagerReadOnly, isSacUser } from '../../helpers/roles'
import { alertActions } from '../../redux/actions/alert_actions'
import { customerActions } from '../../redux/actions/customers_actions'
import { discountsActions } from '../../redux/actions/discounts_actions'
import { userService } from '../../services/userService'
import CustomMaterialTable from '../shared/customMaterialTable'
import { displayWithTooltip } from '../shared/enshortenLargeData'
import ModalConfirm from '../shared/modalconfirm'
import Batteries from './dialogs/batteries'
import Create from './dialogs/create'
import Details from './dialogs/details'
import Discounts from './dialogs/discounts'
import Rates from './dialogs/rates'
import Swaps from './dialogs/swap'
import CustomerDocumentsValidation from './documentation'
import { exportCsv } from '../../helpers/exportCsv'
import { useLocation } from 'react-router-dom'

const CustomerView = ({
  customerReducer,
  getAllCustomers,
  getByCustomer,
  getAllCountries,
  getAllDocTypes,
  updatePaymentMode,
}) => {
  const { results } = customerReducer
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()

  const [selectedRow, setselectedRow] = useState({})

  const [isCreateDialogOpen, setisCreateDialogOpen] = useState(false)
  const [isRatesDialogOpen, setisRatesDialogOpen] = useState(false)
  const [isBatteriesDialogOpen, setisBatteriesDialogOpen] = useState(false)
  const [isDiscountsDialogOpen, setisDiscountsDialogOpen] = useState(false)
  const [isSwapsDialogOpen, setisSwapsDialogOpen] = useState(false)
  const [isDetailsDialogOpen, setisDetailsDialogOpen] = useState(false)
  const [deleteCustomerModal, setDeleteCustomerModal] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState('')

  useEffect(() => {
    getAllCustomers()
    getAllCountries()
    getAllDocTypes()

    return () => dispatch(customerActions.closeValidationsModal())
  }, [])

  useEffect(() => {
    if (!results.length) return

    const match = location.pathname.match(
      /\/([0-9a-fA-F]{24})\/(validate-documentation|discounts)/,
    )
    if (!match) return

    const customer = results.find((customer) => customer.id === match[1])
    const action = match[2] === 'discounts' ? 'discounts' : 'validate'

    handleActions(customer, action)
  }, [results])

  const handleActions = (rowData, action) => {
    rowData && action !== 'changePaymentMode' && setselectedRow(rowData)
    switch (action) {
      case 'validate':
        dispatch(customerActions.openValidationsModal())
        document
          .getElementById('dashboard')
          .scrollTo({ top: 0, behavior: 'auto' })
        break
      case 'rates':
        setisRatesDialogOpen(true)
        break
      case 'batteries':
        setisBatteriesDialogOpen(true)
        break
      case 'changePaymentMode':
        updatePaymentMode(rowData?.id)
        break
      case 'discounts':
        getByCustomer()
        setisDiscountsDialogOpen(true)
        break
      case 'details':
        if (isFinancial()) return
        setisDetailsDialogOpen(true)
        break
      case 'swaps':
        setisSwapsDialogOpen(true)
        break
      case 'disable':
        break
      default:
        break
    }
  }

  const handleDeleteCustomer = () => {
    userService.deleteCustomer(selectedEmail).then(
      (result) => {
        //IF OK -> REFRESH DATA
        dispatch(alertActions.success(t('customers.page.delete.success')))
        getAllCustomers()
      },
      (error) => {
        dispatch(alertActions.error(t('customers.page.table.delete.error')))
      },
    )
  }

  return (
    <React.Fragment>
      <CustomMaterialTable
        title={t('customers.page.table.title')}
        isLoading={customerReducer.loadingResults}
        exportCsv={exportCsv}
        exportButton={!isManagerReadOnly() && !isFinancial() && !isCauUser() && !isSacUser()}
        saveFilters
        columns={[
          {
            title: t('customers.page.table.columns.id'),
            field: 'systemId',
            render: (rowData) => displayWithTooltip(rowData.systemId),
            exportFn: (rowData) => rowData.systemId,
          },
          {
            title: t('customers.page.table.columns.name'),
            field: 'fullName',
            render: (rowData) => displayWithTooltip(rowData.fullName),
            exportFn: (rowData) => rowData.fullName,
          },
          {
            title: t('customers.page.table.columns.email'),
            field: 'email',
          },
          {
            title: t('customers.page.table.columns.contactPhone'),
            field: 'phone',
            customFilterAndSearch: (term, rowData) => {
              const trimmedTerm = String(term).replace(/\s+/g, '')
              const phone = String(rowData.phone).replace(/\s+/g, '')
              return phone.includes(trimmedTerm)
            },
          },
          {
            title: t('customers.page.table.columns.city'),
            field: 'city',
          },
          {
            title: t('customers.page.table.columns.paymentMode'),
            field: 'paymentMode',
            render: (rowData) => PaymentMode[rowData.paymentMode],
            lookup: { 0: PaymentMode[0], 1: PaymentMode[1] },
            customFilterAndSearch: (term, rowData) =>
              term.length !== 1 || rowData.paymentMode === parseInt(term[0]),
          },
          {
            title: t('customers.page.table.columns.enabled'),
            field: 'cannotOperate',
            addlookup: true,
          },
          {
            title: t('customers.page.table.columns.autoSwap'),
            lookup: {
              0: t('customers.customerData.labels.yes'),
              1: t('customers.customerData.labels.no'),
            },
            customFilterAndSearch: (term, rowData) =>
              handleTrueFalsyTermSwitch(term, rowData.hasActiveAutoSwap),
            render: (rowData) => {
              return rowData.hasActiveAutoSwap
                ? t('customers.customerData.labels.yes')
                : t('customers.customerData.labels.no')
            },
          },
        ]}
        data={results}
        onRowClick={(rowData) => handleActions(rowData, 'details')}
        rowClickAvaiable
        actions={[
          (rowData) => ({
            icon: 'attach_file',
            tooltip: t('customers.page.table.actions.validateDocumentation'),
            onClick: (_, rowData) => {
              handleActions(rowData, 'validate')
            },
            iconProps: {
              style: {
                color: !rowData.cannotOperate ? 'green' : 'red',
              },
            },
            hidden: isCauUser(),
          }),
          {
            icon: 'card_membership',
            tooltip: t('customers.page.table.actions.rates'),
            onClick: (_, rowData) => handleActions(rowData, 'rates'),
          },
          {
            icon: 'battery_charging_full',
            tooltip: t('customers.page.table.actions.batteries'),
            onClick: (_, rowData) => handleActions(rowData, 'batteries'),
          },
          {
            icon: 'credit_card',
            tooltip: t('customers.page.table.actions.changePaymentMode'),
            onClick: (_, rowData) =>
              handleActions(rowData, 'changePaymentMode'),
            hidden: isSacUser() || isCauUser(),
          },
          {
            icon: 'local_offer',
            tooltip: t('customers.page.table.actions.discounts'),
            onClick: (_, rowData) => handleActions(rowData, 'discounts'),
            hidden: isFinancial()
          },
          {
            icon: 'swap_horizontal_circle',
            tooltip: t('customers.page.table.actions.swap'),
            onClick: (_, rowData) => handleActions(rowData, 'swaps'),
            hidden: isFinancial(),
          },
          {
            icon: 'remove_circle_outline',
            tooltip: t('customers.page.table.actions.disable'),
            onClick: (_, rowData) => handleActions(rowData, 'disable'),
            hidden: isSacUser() || isCauUser() || isFinancial(),
          },
          () => ({
            icon: 'delete',
            tooltip: t('customers.page.table.actions.delete'),
            onClick: (_, rowData) => {
              setDeleteCustomerModal(true)
              setSelectedEmail(rowData.email)
            },
            hidden: !isManager() || isFinancial(),
          }),
        ]}
      />

      <Create
        isOpen={isCreateDialogOpen}
        onClose={() => setisCreateDialogOpen(false)}
      />
      {customerReducer.validationModalOpened && (
        <CustomerDocumentsValidation
          open={customerReducer.validationModalOpened}
          customerId={selectedRow.id}
          customerData={selectedRow}
          onClose={() => {
            dispatch(customerActions.closeValidationsModal())
            dispatch(customerActions.clearSelectedCustomer())
          }}
        />
      )}
      <Swaps
        isOpen={isSwapsDialogOpen}
        onClose={() => setisSwapsDialogOpen(false)}
        selectedRow={selectedRow}
      />

      <Batteries
        isOpen={isBatteriesDialogOpen}
        onClose={() => setisBatteriesDialogOpen(false)}
        selectedRow={selectedRow}
      />
      <Details
        isOpen={isDetailsDialogOpen}
        onClose={() => setisDetailsDialogOpen(false)}
        selectedRow={selectedRow}
      />
      <Rates
        isOpen={isRatesDialogOpen}
        onClose={() => setisRatesDialogOpen(false)}
        selectedRow={selectedRow}
      />
      <Discounts
        isOpen={isDiscountsDialogOpen}
        onClose={() => setisDiscountsDialogOpen(false)}
        selectedRow={selectedRow}
      />
      <ModalConfirm
        title={t('customers.page.table.delete.title')}
        isOpen={deleteCustomerModal}
        onConfirm={() => {
          handleDeleteCustomer()
          setDeleteCustomerModal(false)
          setSelectedEmail('')
        }}
        onClose={() => {
          setDeleteCustomerModal(false)
          setSelectedEmail('')
        }}
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { customerReducer } = state
  return { customerReducer }
}
const actionCreators = {
  getAllCustomers: customerActions.getAllCustomers,
  getByCustomer: discountsActions.getByCustomer,
  getAllCountries: customerActions.getAllCountries,
  getAllDocTypes: customerActions.getAllDocTypes,
  updatePaymentMode: customerActions.updatePaymentMode,
}

export default connect(mapState, actionCreators)(CustomerView)
