import moment from 'moment'
import React, { useState, useEffect, useCallback } from 'react'
import { authHeader } from '../../helpers/authHeader'
import {
  isCauUser,
  isFinancial,
  isManager,
  isManagerReadOnly,
  isSacUser,
} from '../../helpers/roles'
import CustomMaterialTable from '../shared/customMaterialTable'
import DatePickerCustom from '../shared/datePickerCustom'
import { displayWithTooltip } from '../shared/enshortenLargeData'
import ModalConfirm from '../shared/modalconfirm'
import BillingDetails from './modals/details'
import { useLocation } from 'react-router-dom'

const BillingView = ({
  t,
  billingReducer,
  getAll,
  getPaymentIntents,
  refundPayment,
}) => {
  const location = useLocation()
  const [modalPayment, setModalPayment] = useState(false)
  const [modalHistory, setModalHistory] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [item, setItem] = useState(null)

  const openModalPayment = (rowData) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = function () {
      const blob = xhr.response
      const file = new Blob([blob], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    }
    const header = authHeader()
    xhr.open('GET', rowData.fileUrl)
    xhr.setRequestHeader('Authorization', header.Authorization)
    xhr.send()
  }

  const openModalHistory = useCallback(() => {
    setModalHistory(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalPayment(false)
  }, [])

  const closeHistoryModal = useCallback(() => {
    setModalHistory(false)
    window.location.href = '/payments'
  }, [])

  const onClickReturnPayment = (item) => {
    setItem(item)
    setModalPayment(true)
  }

  const statusCodeMap = {
    A: t('billing.page.table.statusDescription.subscribed'),
    C: t('billing.page.table.statusDescription.charged'),
    E: t('billing.page.table.statusDescription.waiting'),
    F: t('billing.page.table.statusDescription.error'),
  }

  const historyMatch = location.pathname.match(/\/([0-9a-fA-F]{24})\/(history)/)

  useEffect(() => {
    if (historyMatch) {
      openModalHistory()
      getPaymentIntents(historyMatch[1])
      setSelectedId(historyMatch[1])
    } else {
      getAll()
    }
  }, [getAll, location.pathname])

  return (
    <>
      <DatePickerCustom searchAction={getAll} />
      <CustomMaterialTable
        title={t('billing.page.table.title')}
        saveFilters
        isLoading={billingReducer.loadingResults}
        data={billingReducer.results}
        columns={[
          {
            title: t('billing.page.table.columns.created'),
            field: 'created',
            render: (rowData) =>
              rowData.created
                ? moment(rowData.created).format('DD/MM/YYYY')
                : '',
          },
          {
            title: t('billing.page.table.columns.code'),
            field: 'invoiceCode',
          },
          {
            title: t('billing.page.table.columns.customerSystemId'),
            field: 'customerSystemId',
          },
          {
            title: t('billing.page.table.columns.refText'),
            field: 'refText',
          },
          {
            title: t('billing.page.table.columns.amount'),
            field: 'amount',
            render: (rowData) => `€${rowData.amount}`,
          },
          {
            title: t('billing.page.table.columns.success'),
            field: 'successTimestamp',
            render: (rowData) =>
              rowData.successTimestamp
                ? moment(rowData.successTimestamp).format('DD/MM/YYYY HH:mm')
                : '',
          },
          {
            title: 'Status code',
            field: 'statusCode',
            lookup: statusCodeMap,
            render: (rowData) => statusCodeMap[rowData.statusCode] ?? '',
          },
          {
            title: t('billing.page.table.columns.failedReason'),
            field: 'failedReason',
            render: (rowData) => {
              let reason = rowData.failedReason

              if (String(rowData.failedReason).includes('insufficient')) {
                reason = t('billing.page.table.columns.insufficientFunds')
              }

              return displayWithTooltip(reason)
            },
          },
        ]}
        actions={[
          {
            icon: 'get_app',
            tooltip: t('billing.page.table.actions.download'),
            onClick: (event, rowData) => openModalPayment(rowData),
          },
          {
            icon: 'assignment',
            tooltip: t('billing.page.table.actions.history'),
            onClick: (event, rowData) => {
              openModalHistory()
              getPaymentIntents(rowData.id)
              setSelectedId(rowData.id)
            },
            hidden:
              !isManager() &&
              !isManagerReadOnly() &&
              !isFinancial() &&
              !isSacUser() &&
              !isCauUser(),
          },
          (rowData) => ({
            icon: 'replay',
            tooltip: t('billing.page.table.actions.return'),
            onClick: (event, rowData) => onClickReturnPayment(rowData),
            hidden:
              !rowData.success ||
              (!isManager() && !isSacUser()) ||
              rowData.refundTimestamp != null ||
              ['BA', 'FA', 'BAS'].includes(rowData.invoiceType),
          }),
        ]}
        exportButton={!isCauUser() && !isSacUser()}
      />
      <ModalConfirm
        isOpen={modalPayment}
        onClose={closeModal}
        onConfirm={() => refundPayment(item.id)}
        title={`${t('billing.page.modal.title')} (€${item?.amount})`}
        body={t('billing.page.modal.body')}
      />
      <BillingDetails
        isOpen={modalHistory}
        onClose={closeHistoryModal}
        reducer={billingReducer}
        filter={getPaymentIntents}
        selectedId={selectedId}
      />
    </>
  )
}

export default BillingView
